import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  
  { // 首页
    path: '/',
    mode:'history',
    base:'/vue/',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home/home.vue')
      }, 
      { // Company Profile
        path: '/CompanyProfile',
        name: 'CompanyProfile',
        component: () => import('@/views/menu/CompanyProfile.vue')
      },
      {
        // Hot Destination
        path: '/HotDestination',
        name: 'HotDestination',
        component: () => import('@/views/menu/HotDestination.vue')
      },
      {
        // Our Services
        path: '/OurServices',
        name: 'OurServices',
        component: () => import('@/views/menu/OurServices.vue')
      },
      {
        // Register
        path: '/Register',
        name: 'Register',
        component: () => import('@/views/menu/Register.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  devtools: true
  // 路由配置...
})

export default router
